
import { FaroIconButton } from "@components/common/faro-icon-button";
import { useCallback, useRef, useState } from "react";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { Box, Divider } from "@mui/material";
import { AnnotationEvents, TableType } from "@utils/track-event/track-event-list";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { sphereColors } from "@styles/common-colors";
import { FaroMenu, FaroMenuItem } from "@faro-lotv/flat-ui";
import VerticalDotsIcon from "@assets/icons/new/vertical-dots_28px.svg?react";
import { useToast } from "@hooks/use-toast";
import { useSharedMarkupActions } from "@hooks/project-markups/use-shared-markups-actions";
import { SphereShareLink } from "@components/common/sphere-share-link";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { SHARE_ANNOTATION_DIALOG_PROVIDER_PROPS } from "@pages/project-details/project-markups/markup-action-share-markup";
import { useAppDispatch } from "@store/store-helper";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { updateSidebar } from "@store/ui/ui-slice";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { DeleteMarkupDialog } from "@pages/project-details/project-markups/markup-delete-dialog";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";


/**
 * Component that represents an action button to give the user more options .
 * Renders an icon button that, when clicked, opens a menu item  that each item render one action.
 *
 * @param markup The properties of the markup to be opened
 * @returns A JSX element representing the action button to give the user more options.
 */
export function MarkupActionMoreOptions({ markup }: BaseMarkupProps): JSX.Element | null {

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { showToast } = useToast();
  const { createDialog } = useDialog();
  const { markupHref } = useSharedMarkupActions({ markup });
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();

  const {
    hasPermissionToEditMarkup,
    projectId,
    updateMarkups,
    markups,
    updateIsSidePanelOpen,
    updateSelectedMarkup,
    updateActiveTab, 
  } = useMarkupContext();

  const { deleteMarkupFromProject } = useProjectMarkupUpdate({ projectId });
  const dispatch = useAppDispatch();

  /** Handles sharing a markup by link */
  const onShareMarkup = useCallback((): void => {
      trackEvent({ name: AnnotationEvents.shareAnnotation });
      // Early exit if there isn't a markup URL to share
      if (!markupHref) {
        return;
      }
      createDialog(
        SHARE_ANNOTATION_DIALOG_PROVIDER_PROPS,
        <SphereShareLink title="Annotation link" url={markupHref} />
      );
    },
    [createDialog, markupHref, trackEvent]
  );

  /** Handles opening of markup in a new tab */
  function onOpenMarkup(): void{
    trackEvent({
      name: AnnotationEvents.openAnnotationInNewTab,
      props: { dataType: TableType.projectMarkups },
    });
    if (!markupHref) {
      return;
    }
    window.open(markupHref, "_blank")?.focus();
  };

  /** Handles viewing the details of a markup */
  function onViewDetails(): void{
    trackEvent({
      name: AnnotationEvents.viewAnnotationDetails,
      props: { dataType: TableType.projectMarkups },
    });
    updateIsSidePanelOpen(true);
    updateSelectedMarkup(markup);
    updateActiveTab("Info");
    dispatch(updateSidebar({ isFullSize: false }));
  };

  /** Menu items to be displayed in the VerticalDotsIcon button*/
  const menuItems = [
    {
      label: "Open in new tab",
      onClick: onOpenMarkup,
      isVisible: true,
    },
    {
      type: "divider",
      isVisible: true,
    },
    {
      label: "View details",
      onClick: onViewDetails,
      isVisible: true,
    },
    {
      label: "Share",
      onClick: onShareMarkup,
      isVisible: true,
    },
    {
      label: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
      color: sphereColors.red600,
      highlightColor: sphereColors.red600,
      isVisible: hasPermissionToEditMarkup,
    },
  ];

  /** Handles removing a markup */
  async function removeMarkupFromProject(): Promise<void> {
    setIsDeleting(true);
    trackEvent({
      name: AnnotationEvents.deleteAnnotation,
      props: { dataType: TableType.projectMarkups },
    });

    try {
      await dispatch(() => deleteMarkupFromProject(markup));
      showToast({
        message: "Annotation deleted.", 
        type: "success",
      });

      const updatedMarkups = markups.filter((m) => m.id !== markup.id);
      updateMarkups(updatedMarkups);
      
    } catch (error) {
      handleErrorWithToast({
        id: `Delete annotation-${Date.now().toString()}`,
        title: "Failed to delete the annotation.",
        error: getErrorDisplayMarkup(error),
      });

    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    }
  }

 return (
    <Box
      sx={{
        display: "inline-block",
        position: "relative",
      }}
    >
     <FaroIconButton
        aria-label="more"
        aria-haspopup="true"
        component={VerticalDotsIcon}
        buttonSize="30px"
        iconSize="26px"
        onClick={() => setIsMenuOpen(true)}
        iconButtonProps={{ ref: anchorRef , sx: { marginRight: "10px" } }}
      />
      <FaroMenu
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: "4px",
          paddingX: "0px",
          fontSize: "14px",
          borderRadius: "4px",
          fontWeight: "400",
           "& .MuiMenu-paper" : {
            minWidth: "220px",
           },
        }}
      >
        {
          menuItems.filter((menuItem)=> menuItem.isVisible).map((item) => {
            if (item.type === "divider") {
              return (
                <Divider
                  key="divider"
                  orientation="horizontal"
                  flexItem
                  sx={{
                    color: sphereColors.gray200,
                    margin: "4px",
                  }}
                />
              );
            }
            return (
              <FaroMenuItem
                key={item.label}
                label={item.label}
                onClick={()=>{
                  setIsMenuOpen(false);
                  item.onClick && item.onClick();
                }}
                color= {item.color}
                highlightColor= {item.highlightColor}
                sx={{
                  paddingX: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            );
          })
        }
      </FaroMenu>

      {/* Delete Confirmation Dialog */}
      {isDeleteModalOpen && (
        <DeleteMarkupDialog
          dialogContent={{
            title: "Delete annotation?",
            description: "This will remove the annotation from your project.",
          }}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={removeMarkupFromProject}
          isDeleting={isDeleting}
        />
      )}
      
    </Box>  
  );
}

