// Functions and variables in this file are intended to create strings
// out of specific objects and format them so they can be
// displayed in a human-readable form.

import { DateTimeUtils } from "@stellar/web-core";

import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import {
  TEAM_DISPLAY_NAME,
  TEAMS_DISPLAY_NAME,
} from "@src/constants/team-constants";

/**
 * Return readable text for company and project roles
 */
export function formatUserRoleType(
  userRole: CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole,
  options?: {
    /**
     * Optional context for the project roles.
     */
    projectContext?: "project" | "snapshot" | "group" | "company";
  }
): string {
  // For snapshot we simplify and to not add a prefix to the role.
  let customProjectRolePrefix: string;
  // We use project as default context for the project roles.
  switch (options?.projectContext ?? "project") {
    case "project":
    case "group":
    case "company":
      customProjectRolePrefix = "Project ";
      break;
    default:
      customProjectRolePrefix = "";
  }

  switch (userRole) {
    case CoreAPITypes.EUserProjectRole.admin:
      return `${customProjectRolePrefix}Admin`;
    case CoreAPITypes.EUserProjectRole.editor:
      return `${customProjectRolePrefix}Editor`;
    case CoreAPITypes.EUserProjectRole.viewer:
      return `${customProjectRolePrefix}Viewer`;
    case CoreAPITypes.EUserCompanyRole.companyExecutive:
      return "Admin";
    case CoreAPITypes.EUserCompanyRole.companyViewer:
      return "Viewer";
    case CoreAPITypes.EUserCompanyRole.companyManager:
      return "Group Manager";
    case CoreAPITypes.EUserProjectRole.owner:
    case CoreAPITypes.EUserCompanyRole.projectManager:
      return `${customProjectRolePrefix}Manager`;
    case CoreAPITypes.EUserCompanyRole.member:
      return "No workspace role";
  }
}

/**
 * Returns the pretty version of a date.
 * If the date is today or in the future returns "Today".
 * In the future we can expand this function to return, dates in the future. E.g. in 3 days.
 *
 * @param date Date Number of milliseconds elapsed since midnight, January 1, 1970 Universal Coordinated Time (UTC).
 * @returns The pretty version of the date. E.g. Yesterday, 2 days ago.
 */
export function getPrettyDateElapsed(date: number): string {
  const days = DateTimeUtils.diffFromTodayInDays({
    date: new Date(date),
  });

  if (days >= 0) {
    // If getPrettyDateElapsed returns an empty string it means that either the date was today or tomorrow.
    // In that case we just write Today because a project could not have been modified in the future.
    return "Today";
  }

  // Unfortunately the function diffFromTodayInDays returns 1 day offset
  // e,g, for a date that happened yesterday returns 2, therefore we deduct one day from the total calculation.
  // days is negative as all the dates from here on are in the past, hence we multiple with -1 to have a positive value.
  const daysWithOffset = days * -1 - 1;

  if (daysWithOffset === 1) {
    return "Yesterday";
  }

  const DAYS_LIMIT = 90;
  if (daysWithOffset < DAYS_LIMIT) {
    return `${daysWithOffset} days ago`;
  }

  const DAYS_LIMIT_MONTH = 731;
  if (daysWithOffset <= DAYS_LIMIT_MONTH) {
    const DAYS_IN_MONTH = 30;
    const months = Math.round(daysWithOffset / DAYS_IN_MONTH);
    return `${months} months ago`;
  }

  const DAYS_IN_YEAR = 365;
  const years = Math.round(daysWithOffset / DAYS_IN_YEAR);
  return `${Math.round(years)} years ago`;
}

/** Return the singular/plural term of a noun */
export const nounList = {
  /* eslint-disable @typescript-eslint/naming-convention */
  project: "projects",
  member: "members",
  annotation: "annotations",
  group: "groups",
  this: "these",
  role: "roles",
  "this user": "these users",
  Attachment: "Attachments",
  day: "days",
  [TEAM_DISPLAY_NAME]: TEAMS_DISPLAY_NAME,
  /* eslint-enable */
};

interface NounPluralizeProps {
  /** Whether or not to show the counter before the noun */
  shouldShowCounter?: boolean;

  /** The actual number */
  counter: number;

  /** The word in singular term */
  word: keyof typeof nounList;
}

/** Return the singular/plural term of a noun with/without the actual counter */
export function nounPluralize({
  shouldShowCounter = true,
  counter,
  word,
}: NounPluralizeProps): string {
  const count = shouldShowCounter ? `${counter} ` : "";

  return counter === 1 ? count + word : count + nounList[word];
}

/** Return the verb in progressive format */
export const progressiveVerbList = {
  delete: "deleting",
  change: "changing",
  archive: "archiving",
  unarchive: "unarchiving",
  remove: "removing",
};

interface ProgressiveVerbProps {
  /** Whether or not the verb is in progressive */
  isProgressive: boolean;

  /** The verb in present tense */
  verb: keyof typeof progressiveVerbList;
}

/** Return the progressive verb tense of a given verb */
export function progressiveVerb({
  verb,
  isProgressive,
}: ProgressiveVerbProps): string {
  return isProgressive ? progressiveVerbList[verb] : verb;
}

/** Return the formatted version of area unit */
export function formatAreaUnit(unit: AdminAPITypes.EAreaUnit): string {
  switch (unit) {
    case AdminAPITypes.EAreaUnit.sqft:
      return "Sq ft";
    case AdminAPITypes.EAreaUnit.sqm:
      return "Sq m";
  }
}

/** Returns user role description */
export function userRoleDescription(
  userRole: CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole
): string {
  switch (userRole) {
    case CoreAPITypes.EUserCompanyRole.companyExecutive:
      return "Complete control and access to the entire workspace and all settings.";

    case CoreAPITypes.EUserCompanyRole.companyViewer:
      return "Access to all projects of the workspace with viewing permission only.";

    case CoreAPITypes.EUserCompanyRole.companyManager:
      return "Manages one or multiple groups of projects and project managers.";

    case CoreAPITypes.EUserCompanyRole.projectManager:
      return "Manages one or multiple projects and access to them.";

    case CoreAPITypes.EUserCompanyRole.member:
      return "Access is restricted to individual projects.";

    case CoreAPITypes.EUserProjectRole.admin:
      return "Can manage and edit individual projects they are invited to.";

    case CoreAPITypes.EUserProjectRole.editor:
      // eslint-disable-next-line max-len
      return "Can edit invited projects but cannot access project properties, invite users, or change details and access levels.";

    case CoreAPITypes.EUserProjectRole.viewer:
      return "Can view individual active projects that they are invited to or have received a URL link for.";

    case CoreAPITypes.EUserProjectRole.owner:
      // eslint-disable-next-line max-len
      return "It is the owner of the project and can view and edit project details, invite other users, and change access levels.";

    default:
      return "";
  }
}
