import {
  ProjectMarkupButtonComponents,
  ProjectMarkupTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useMemo } from "react";
import { Markup } from "@custom-types/project-markups-types";
import { ExportMarkupsAsCSV } from "@pages/project-details/project-markups/project-markups-bulk-actions/export-markups-as-csv";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { BulkDeleteMarkups } from "@pages/project-details/project-markups/project-markups-bulk-actions/bulk-delete-markups";
import { useMarkupContext } from "@context-providers/markup/markup-context";

interface Props extends BaseProjectIdProps {
  /** List of markups to show in table */
  markups: Markup[];
}

/** List all the bulk action components for project markup table */
export function ProjectMarkupBulkActions({
  markups,
  projectId,
}: Props): ProjectMarkupButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("markups"));
  const isAnyRowSelected = selectedEntities.length !== 0;
  const {
    hasPermissionToEditMarkup,
  } = useMarkupContext();
  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));

  const allowedButtonsBasedOnRowSelection: ProjectMarkupTableBulkName[] =
    useMemo(() => {
      const allowedButtons: ProjectMarkupTableBulkName[] = ["exportCsv"];

      if (isAnyRowSelected && hasPermissionToEditMarkup) {
        allowedButtons.push("deleteMarkup");
      }
      return allowedButtons;
    }, [isAnyRowSelected, hasPermissionToEditMarkup]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  return {
    deleteMarkup: shouldShowBulkButtons("deleteMarkup") ? (
      <BulkDeleteMarkups
       selectedMarkups={isAnyRowSelected ? selectedEntities : []}
       projectId={projectId} />
    ) : null, 

    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <ExportMarkupsAsCSV
        selectedMarkups={isAnyRowSelected ? selectedEntities : markups}
        projectName={selectedProject?.name ?? ""}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
