import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { Markup } from "@custom-types/project-markups-types";
import { createEditStatusMutation } from "@pages/project-details/project-markups/status/markup-status-utils";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  AnnotationStatus,
  IElementAttachment,
  IElementType,
  IElementTypeHint,
  IElementUserDirectoryMarkupField,
} from "@faro-lotv/ielement-types";
import { generateGUID } from "@faro-lotv/foundation";
import {
  createMutationAddMarkupField,
  createMutationUserDirectoryMarkupField,
  createMutationDeleteElement,
  Mutation,
  MutationDeleteElement,
  MutationUserDirectoryMarkupField,
  MutationAddMarkupField,
  MutationAddAttachment,
  MutationResult,
  ApiResponseError,
} from "@faro-lotv/service-wires";
import {
  NewAttachment,
  createAttachmentMutation,
} from "@pages/project-details/project-markups/markup-attachment-utils";
import { useAppDispatch } from "@store/store-helper";
import { removeIElement } from "@faro-lotv/project-source";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { shouldDeleteModel3D } from "@store/markups/markups-selector-utils";
import {
   UseProjectMarkupUpdate,
   BulkMarkupDeletionResult,
   MarkupDeletionResult,
   UpdateMarkupStatus,
   AddAttachmentToMarkup, 
  } from "@hooks/project-markups/project-markup-types";

/** Custom hook that takes care of fetching all markup elements and other associated elements */
export function useProjectMarkupUpdate({
  projectId,
}: BaseProjectIdProps): UseProjectMarkupUpdate {
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  /**
   * Updates the status of a markup
   */
  async function updateMarkupStatus({
    markup,
    newStatus,
    statusTemplateId,
  }: UpdateMarkupStatus): Promise<Mutation[] | undefined> {
    try {
      // If the new status is "unclassified", for mutation the value should undefined to create delete mutation
      if (newStatus === AnnotationStatus.Unclassified) {
        newStatus = undefined;
      }

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "status", isValueEmpty: !newStatus },
      });

      const mutations = createEditStatusMutation(
        markup,
        newStatus,
        markup.status?.id,
        statusTemplateId
      );

      await projectApiClient.applyMutations(mutations);

      return mutations;
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationStatus-${Date.now().toString()}`,
        title: "Could not change annotation status. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**
   * Handles the removal of a member assigned to an annotation markup.
   *
   * @param assigneeId The ID of the assignee to be removed.
   * @returns A Promise that resolves when the member is successfully removed.
   */
  async function removeMember(assigneeId: string): Promise<void> {
    try {
      const removeMember = createMutationDeleteElement(assigneeId);

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "assignee", isValueEmpty: true },
      });

      // Delete the assignee element using projectApiClient
      await projectApiClient.applyMutations([removeMember]);
    } catch (error) {
      handleErrorWithToast({
        id: `deleteAnnotationAssignee-${Date.now().toString()}`,
        title: "Could not delete the annotation assignee. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**
   * Adds a new member as an assignee for the markup.
   */
  async function updateNewMember(
    markup: Markup,
    newMember: SphereDashboardAPITypes.IProjectMemberBase,
    assigneeTemplateId: string
  ): Promise<
    | MutationUserDirectoryMarkupField
    | MutationAddMarkupField<IElementUserDirectoryMarkupField>
    | undefined
  > {
    try {
      let mutation:
        | MutationUserDirectoryMarkupField
        | MutationAddMarkupField<IElementUserDirectoryMarkupField>;

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "assignee", isValueEmpty: false },
      });

      // Edit previous value if it exists
      if (typeof markup.assignee !== "undefined") {
        mutation = createMutationUserDirectoryMarkupField(markup.assignee.id, [
          newMember.identity,
        ]);

        await projectApiClient.applyMutations([mutation]);
      } else {
        mutation =
          createMutationAddMarkupField<IElementUserDirectoryMarkupField>(
            markup.id,
            {
              id: generateGUID(),
              childrenIds: null,
              name: "Assignee",
              parentId: markup.id,
              rootId: markup.rootId,
              templateId: assigneeTemplateId,
              type: IElementType.userDirectoryMarkupField,
              typeHint: IElementTypeHint.markupAssigneeId,
              values: [newMember.identity],
            }
          );

        // Create a new node if it did not exists
        await projectApiClient.applyMutations([mutation]);
      }

      return mutation;
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationAssignee-${Date.now().toString()}`,
        title: "Could not update the annotation assignee. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**  Adds attachments to a markup */
  async function addAttachmentsToMarkup({
    uploadedFiles,
    markup,
    attachmentGroup,
  }: AddAttachmentToMarkup): Promise<MutationAddAttachment[] | undefined> {
    try {
      const mutations = uploadedFiles.map((uploadedFile) => {
        const newAttachment: NewAttachment = {
          id: generateGUID(),
          fileName: uploadedFile.fileName,
          downloadUrl: uploadedFile.downloadUrl,
          md5: uploadedFile.md5,
          fileSize: uploadedFile.fileSize,
          date: new Date().toISOString(),
          urlOrFile: uploadedFile.downloadUrl,
        };

        return createAttachmentMutation(
          markup.rootId,
          markup.id,
          newAttachment,
          attachmentGroup?.id
        );
      });

      await projectApiClient.applyMutations(mutations);

      return mutations;
    } catch (error) {
      handleErrorWithToast({
        id: `addAttachmentsToMarkup-${Date.now().toString()}`,
        title: "Could not add attachment(s). Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**  Delete attachment to a markup */
  async function deleteAttachmentToMarkup(
    attachment: IElementAttachment
  ): Promise<MutationDeleteElement | undefined> {
    try {
      const deleteAttachment = createMutationDeleteElement(attachment.id);

      await projectApiClient.applyMutations([deleteAttachment]);

      await dispatch(removeIElement(attachment.id));

      return deleteAttachment;
    } catch (error) {
      handleErrorWithToast({
        id: `deleteAttachmentImage-${Date.now().toString()}`,
        title: "Could not delete the attachment. Please try again",
        error: error,
      });
    }
  }

  /** Delete a markup from the project */
  async function deleteMarkupFromProject(
    selectedMarkup: Markup
  ): Promise<MutationResult> {

    const elementsIdToDelete = shouldDeleteModel3D(selectedMarkup.model3d, selectedMarkup.id)
      ? selectedMarkup.model3d?.id ?? selectedMarkup.id
      : selectedMarkup.id;

    const deletedMarkup = createMutationDeleteElement(elementsIdToDelete, true);

    const response = await projectApiClient.applyMutations([deletedMarkup]);
    dispatch(removeIElement(selectedMarkup.id));
    
    return response[0];

  }
  

  /** Delete a bulk of markups from the project */
  async function bulkDeleteMarkupsFromProject(
    selectedMarkups: Markup[]
  ): Promise<BulkMarkupDeletionResult> {
    const referenceIdToElementId = new Map<string, string>();
  
    const deletedMarkups = createDeletedMarkups(selectedMarkups, referenceIdToElementId);
  
    const finalResults = await applyMutationsWithRetries(deletedMarkups, referenceIdToElementId);
  
    return {
      successMarkups: mapFinalMarkups(finalResults.successMarkups, referenceIdToElementId),
      failedMarkups: mapFinalMarkups(finalResults.failureMarkups, referenceIdToElementId),
    };
  }

  // Maps MutationResults to MarkupDeletionResults by adding the corresponding 'elementId' to each one .
  function mapFinalMarkups(results: MutationResult[], refIdToElementId: Map<string, string>): MarkupDeletionResult[] {
    return results.map((res) => ({
      ...res,
      elementId: refIdToElementId.get(res.referenceId ?? "") ?? "",
    }));
  }
  
  /** Create delete mutations for markups */
  function createDeletedMarkups(
    selectedMarkups: Markup[],
    referenceIdToElementId: Map<string, string>
  ): ReturnType<typeof createMutationDeleteElement>[] {
    return selectedMarkups.map((markup) => {
      const elementId = shouldDeleteModel3D(markup.model3d, markup.id)
        ? markup.model3d?.id ?? markup.id
        : markup.id;
  
      const mutation = createMutationDeleteElement(elementId, true);
      // Map reference id to element id, so we don't have to search for the markup id later
      referenceIdToElementId.set(mutation.id, markup.id);
  
      return mutation;
    });
  }

  /** Apply mutations with retries */
  async function applyMutationsWithRetries(
    toProcess: ReturnType<typeof createMutationDeleteElement>[],
    referenceIdToElementId: Map<string, string>,
    accumulatedSuccess: MutationResult[] = [],
    accumulatedFailure: MutationResult[] = [],
    accumulatedPending: MutationResult[] = []
  ): Promise<{ successMarkups: MutationResult[]; failureMarkups: MutationResult[]; pendingMarkups: MutationResult[] }> {
    while (toProcess.length > 0) {
      try {
        const response = await projectApiClient.applyMutations(toProcess, true);
        accumulatedSuccess.push(...response);
  
        referenceIdToElementId.forEach((id) => dispatch(removeIElement(id)));
  
        return { successMarkups: accumulatedSuccess, failureMarkups: accumulatedFailure, pendingMarkups: accumulatedPending };
      } catch (error) {
        if (error instanceof ApiResponseError) {
          const response = error.body.data as MutationResult[];
          const newSuccess = response.filter((r) => r.status === "success");
          const newFailure = response.filter((r) => r.status === "failure");
          const newPending = response.filter((r) => r.status === "pending");
  
          accumulatedSuccess.push(...newSuccess);
          accumulatedFailure.push(...newFailure);
          accumulatedPending.push(...newPending);
  
          if (newPending.length > 0) {
            const pendingIds = new Set(newPending.map((p) => p.referenceId));
            toProcess = toProcess.filter((m) => pendingIds.has(m.id));
          } else {
            return {
              successMarkups: accumulatedSuccess,
              failureMarkups: accumulatedFailure,
              pendingMarkups: accumulatedPending,
            };
          }
        } else {
          throw error;
        }
      }
    }  
    return { successMarkups: accumulatedSuccess, failureMarkups: accumulatedFailure, pendingMarkups: accumulatedPending };
  }

  return {
    updateMarkupStatus,
    updateNewMember,
    removeMember,
    addAttachmentsToMarkup,
    deleteAttachmentToMarkup,
    deleteMarkupFromProject,
    bulkDeleteMarkupsFromProject,
  };
}

